import React, { useState, useEffect } from 'react';

import { Carousel } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Home = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [blogList, setblogList] = useState();

    const navigateToDetails = (id, name) => {
        const paramsData = { category_id: id, category: name };
        navigate(`/sell-brandwise`, { state: paramsData });
    };



    const navigateToBrandWise = (category_id, brand_id, slug) => {
        const paramsData = { category_id: category_id, brand_id: brand_id };
        navigate(`/phone/` + slug, { state: paramsData });
    };

    const navigateToProductDetails = (id, url) => {
        const paramsData = { id: id };
        navigate(url, { state: paramsData });
    };

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                const response = await axios.get('https://cashknock.in/admin/api/getHomePageData');
                // console.log("banners:",response.data.data[0]);
                if (isMounted) {
                    setData(response.data.data[0]);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    setLoading(false);
                }
            }
        };

        getBlogList()
        fetchData();

        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    }, []);

    const getBlogList = async () => {
        try {
            const res = await axios.get("https://cashknock.in/admin/api/getLatestBlog");
            setblogList(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGetDetail = (url) => {
        const params = { url: url };
        navigate(`/blogs/${url}`, { state: params })
    }


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Home</title>
                </Helmet>
            </HelmetProvider>



            <div id="hero">
                <Carousel autoplay>
                    {loading === false && data?.banners && data?.banners.map((banner) => (
                        banner.banner_type === "1" ?
                            <div key={banner.id} className="swiper-slide">
                                <div className="hero-banner d-flex flex-column justify-content-center align-items-center" style={{ backgroundImage: `url(${banner.image})` }}>
                                    <div className="container" data-aos="fade-in">
                                        <h6>{banner.heading}</h6>
                                        <h1>{banner.content}</h1>
                                        <p>{banner.title}</p>
                                    </div>
                                </div>
                            </div>
                            : null
                    ))}
                </Carousel>
            </div>

            <main id="main">

                <section id="category" className="category">
                    <div class="container">
                        <div class="row g-3">
                            {
                                loading == false && data?.categories && data?.categories.map(option => (
                                    (option.display_at_bottom === 1) ?

                                        <div class="col-4 col-lg-2">
                                            <div class="categ-card">
                                                <a href='javascript:void(0)' onClick={(e) => {
                                                    e.preventDefault(); // Prevent the default anchor click behavior
                                                    navigateToDetails(option.id, option.category);
                                                }} >
                                                    <div>
                                                        <img src={option.image} alt="cashknock category" />
                                                    </div>
                                                    <h4>{option.category}</h4>
                                                </a>
                                            </div>
                                        </div>

                                        : null
                                ))}

                            <div class="col-4 col-lg-2">
                                <div class="categ-card seemore_1 see_more">
                                    <h4 ><Link to="/all-categories">See More</Link></h4>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section id="portfolio" className="portfolio">
                    <div className="container">
                        <div className="section-title">
                            <h2 data-aos="fade-up">Best Seller Products</h2>
                            <p data-aos="fade-up">Whatever you’re looking to sell, we can help. Check out our top seller gadgets.</p>
                        </div>
                        <div className="row">
                            {loading === false && data?.devicemodels && data?.devicemodels.map(products => (
                                <div className="col-lg-2 col-md-3 col-6">
                                    <div className="product-img">
                                        <a href="javascript:void(0)" onClick={() => navigateToProductDetails(products.id, products.url)}><img src={products.image} className="img-fluid" alt="" />
                                            <h4>{products.model}</h4>
                                        </a> </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="services" className="services section-bg-2">
                    <div className="container">
                        <div className="section-title text-white" data-aos="fade-up">
                            <h2 className="text-white">Just Few Steps To Sell Your Phone</h2>
                            <p>Book a free pickup from your home or work at a time slot as per your convenience</p>
                        </div>
                        <div className="row">
                            {loading === false && data?.fewsteps && data?.fewsteps.map(fewsteps => (
                                <div className="col-md-4 col-12" data-aos="fade-up">
                                    <div className="icon-box">
                                        <div className="step-img"><img src={fewsteps.image} alt="cashknock category" />
                                            <div className="c-anim">
                                                <div className="outerCircle"></div>
                                                <div className="innerCircle"></div>
                                                <div className="icon"></div>
                                            </div>
                                        </div>
                                        <h4 className="title"><a href="javasscript:void(0)">{fewsteps.heading}</a></h4>
                                        <p className="description text-white">{fewsteps.content}</p>
                                    </div>
                                </div>
                            ))
                            }

                        </div>
                    </div>
                </section>

                <section id="about" className="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-4 icon-boxes d-flex flex-column align-items-stretch justify-content-center">
                                <h4 data-aos="fade-up">Why Us</h4>
                                <h3 data-aos="fade-up">We are a dedicated bunch of professionals aligned with your goal of maximum satisfaction.</h3>
                                <p data-aos="fade-up"> Not only are we inclined towards providing the best services but we also take utmost joy in ensuring profitability on both sides of a transaction. It is not often that one finds a hospitable buyer that prioritizes the seller’s experience</p>
                            </div>
                            <div className="col-xl-7 col-lg-8" data-aos="fade-right">

                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <div className="serviceBox">
                                            <div className="service-icon"> <span><img src="assets/img/why-us/solution.png" alt="cashknock why us" /></span> </div>
                                            <h3 className="title">One-stop Solution</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="serviceBox red">
                                            <div className="service-icon"> <span><img src="assets/img/why-us/professionals.png" alt="cashknock why us" /></span> </div>
                                            <h3 className="title">Trained Professionals</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="serviceBox blue">
                                            <div className="service-icon"> <span><img src="assets/img/why-us/price.png" alt="cashknock why us" /></span> </div>
                                            <h3 className="title">Amazing Prices</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="serviceBox green">
                                            <div className="service-icon"> <span><img src="assets/img/why-us/quick.png" alt="cashknock why us" /></span> </div>
                                            <h3 className="title">Quick & Hassle-free Payment</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="brands" className="brands">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                                <div className="text-left aos-init aos-animate py-5" data-aos="fade-up">
                                    <h2 className='trending-brands'>Trending Brands</h2>
                                    <p className='brand-model'>Any brand! Any model! Any condition! You can sell it all.</p>
                                </div>
                            </div>
                            <div className="col-md-8 d-flex align-items-center">
                                <Swiper
                                    spaceBetween={20}
                                    modules={[Autoplay]}
                                    slidesPerView={2}
                                    loop
                                    autoplay={true}
                                    breakpoints={{
                                        992: {
                                            slidesPerView: 3
                                        },
                                        1400: {
                                            slidesPerView: 4
                                        }
                                    }}
                                >
                                    {loading === false && data?.brands && data?.brands.map(swiperSliderItem => (
                                        <SwiperSlide key={swiperSliderItem.id}>
                                            <div className="swiper-slide">
                                                <div className="brand-box">
                                                    <a href='javascript:void(0)' onClick={() => navigateToBrandWise(swiperSliderItem.category_id, swiperSliderItem.id, swiperSliderItem.url)}>
                                                        <img src={swiperSliderItem.image} alt="cashknock mobile scroll" />
                                                    </a>
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="get-app" className="portfolio">
                    <div className="container py-5">
                        <div className="row g-4">
                            <div className="col-12 col-md-6 d-none d-md-flex align-items-center justify-content-center">
                                <img src="/assets/img/down-app-3.webp" alt="" width="330px" />
                            </div>
                            <div className="col-12 col-md-6 ">
                                <div className='text-center text-md-start'>
                                    <h2>Download the App</h2>
                                    <p>Unlock seamless convenience with our app, available on the Google Play Store. Download now to enjoy a range of features and updates designed to enhance your experience. Discover real-time notifications and personalized settings to make everything easier. Click the button below to get started!</p>
                                    <div className="d-flex gap-4 justify-content-center justify-content-md-start mt-5 ">
                                        <a href="https://play.google.com/store/apps/details?id=com.cashknock.user" target="_blank">
                                            <img src="/assets/img/down-app-play.webp" alt="error" />
                                        </a>
                                        <Link to="/coming-soon" >
                                            <img src="/assets/img/down-app-apple.webp" alt="error" />
                                        </Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <section id="testimonials" className="testimonials">
                    <div className="container position-relative" data-aos="fade-up">
                        <div className="section-title text-white">
                            <h2 className="text-white" data-aos="fade-up">Hear From Our Customers!</h2>
                            <p data-aos="fade-up">Whatever you’re looking to sell, we can help. Check out our top seller gadgets.</p>
                        </div>

                        <Swiper
                            modules={[Autoplay]}
                            slidesPerView={1}
                            autoplay={true}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2
                                },
                                1250: {
                                    slidesPerView: 4
                                }
                            }}
                            loop>
                            {loading === false && data?.customers && data?.customers.map(customer => (
                                <SwiperSlide >
                                    <div className="testimonial-item">
                                        <img src={customer.image} className="testimonial-img" alt="" />
                                        <h3>{customer.name}</h3>
                                        <h4>{customer.designation}</h4>
                                        <p> <i className="bx bxs-quote-alt-left quote-icon-left"></i> {customer.content}
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>


                    </div>
                </section>

                <section id="faq" className="faq section-bg">
                    <div className="container">
                        <div className="section-title">
                            <h2 data-aos="fade-up">F.A.Q</h2>
                            <p data-aos="fade-up">Frequently Asked Questions</p>
                        </div>
                        <div className="faq-list">
                            <ul>
                                {loading == false && data?.faqs && data?.faqs.map(faq => (
                                    <li key={faq.id} data-aos="fade-up">
                                        <i className="bx bx-help-circle icon-help"></i>
                                        <a
                                            data-bs-toggle="collapse"
                                            className="collapse"
                                            data-bs-target={`#faq-list-${faq.id}`}
                                            aria-expanded="false"
                                        >
                                            {faq.question}
                                            <i className="bx bx-chevron-down icon-show"></i>
                                            <i className="bx bx-chevron-up icon-close"></i>
                                        </a>
                                        <div id={`faq-list-${faq.id}`} className="collapse" data-bs-parent=".faq-list">
                                            <p>{faq.answer}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="counter-wrapper">
                    <div className="counter-inner">
                        <div className="container">
                            <div className="row g-0">
                                <div className="col-lg-2">
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="py-5 text-center text-white">
                                        <div> <i className="bi bi-emoji-smile"></i> </div>
                                        <div className="py-2 count"> <span id="count1">20K</span> </div>
                                        <div> Happy customers </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="py-5 text-center text-white">
                                        <div> <i class="bi bi-cash"></i> </div>
                                        <div className="py-2 count"> <span id="count2">20CR</span>+ </div>
                                        <div> Cash paid to our valuable customers </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="blogs" className="blogs section-bg-1">
                    <div class="container">
                        <div className="section-title">
                            <h2 data-aos="fade-up">Recent Blogs</h2>
                            <p data-aos="fade-up">Stay updated with the latest insights and trends from our recent blogs</p>
                        </div>
                        <div class="row mt-n5">
                            {
                                blogList?.map((ele, ind) =>
                                    parseInt(ele.status, 10) === 1 && ind <= 2 && (
                                        ind == 2 ? <div class="col-md-6 col-lg-4  wow fadeInUp my-2 d-md-none d-lg-block" data-wow-delay=".6s"
                                            style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInUp" }}
                                        >
                                            <div class="blog-grid">
                                                <div class="blog-grid-img position-relative"><img alt="img" src={ele.image} /></div>
                                                <div class="blog-grid-text p-4">
                                                    <h3 class="h5 mb-3"><a href="javascript:void(0)" onClick={() => handleGetDetail(ele.url, ele.id)}>{ele.heading}</a></h3>
                                                    <p class="display-30">{ele.short_description.substring(0, 100)}...</p>
                                                    <div class="meta meta-style2">
                                                        <ul>
                                                            <li>
                                                                <a href="#!"><i class="bi bi-calendar-check me-2"></i>{ele.blog_date}</a></li>
                                                            <li><a href="#!"><i class="fas fa-user"></i> Created by - {ele.blog_by}</a></li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> : <div class="col-md-6 col-lg-4  wow fadeInUp my-2 d-lg-block" data-wow-delay=".6s"
                                            style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInUp" }}
                                        >
                                            <div class="blog-grid">
                                                <div class="blog-grid-img position-relative"><img alt="img" src={ele.image} /></div>
                                                <div class="blog-grid-text p-4">
                                                    <h3 class="h5 mb-3"><a href="javascript:void(0)" onClick={() => handleGetDetail(ele.url, ele.id)}>{ele.heading}</a></h3>
                                                    <p class="display-30">{ele.short_description.substring(0, 100)}...</p>
                                                    <div class="meta meta-style2">
                                                        <ul>
                                                            <li>
                                                                <a href="#!"><i class="bi bi-calendar-check me-2"></i>{ele.blog_date}</a></li>
                                                            <li><a href="#!"><i class="fas fa-user"></i> Created by - {ele.blog_by}</a></li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )

                                )
                            }
                        </div>
                    </div >
                </section>

            </main >

        </>
    )
}

export default Home;